import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"
import ArtVerriere from "../components/tendances/js/artVerriereLoadable"

const ActualitesVerrierePage = () => (
  <>
    <SEO title="Nos nouvelles tendances" />
    <Header />
    <Overlay />
    <ArtVerriere />
    <Contacter />
    <Footer />
    <Copyrights />
  </>
)

export default ActualitesVerrierePage
